<template>
  <div>
    <div class="cple">
      <div class="cple_con">
        <h1>{{ t("什么是CPLE") }}</h1>
        <p>
          {{
            t(
              "CPLE（Carpool Life Economy）是一种基于区块链的顺风车平台的Token，用于奖励用户并促进平台内的经济活动。目前由ITB PLE CO., LIMITED运营，代表项目有“趣出行”和“菠萝快跑”，并正尝试通过BTALK.AI与更多优质项目互相赋能来促进平台内的经济活动。"
            )
          }}
        </p>

        <img src="../../assets/Frame 1321315022.png" class="cple_img" alt="" />
      </div>
    </div>

    <div class="cple_page">
      <div class="cple_con">
        <h1>{{ t("什么是CPLE") }}</h1>
        <p>
          {{
            t(
              "CPLE（Carpool Life Economy）是一种基于区块链的顺风车平台的Token，用于奖励用户并促进平台内的经济活动。目前由ITB PLE CO., LIMITED运营，代表项目有“趣出行”和“菠萝快跑”，并正尝试通过BTALK.AI与更多优质项目互相赋能来促进平台内的经济活动。"
            )
          }}
        </p>
        <div class="cple_img">
          <img
            src="../../assets/Frame 1321315023.png"
            class="cple_img"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
const { t } = useI18n();
</script>

<style lang="scss" scoped>
@media (max-width: 600px) {
  .cple {
    display: none;
  }

  .cple_page {
    display: block;
    width: 95%;
    margin: 80px auto;
    color: #fff;

    h1 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    p {
      font-size: 15px;
      font-weight: 400;
      text-align: justify;
    }

    .cple_img {
      display: flex;
      justify-content: center;

      img {
        width: 343px;
        height: 171px;
        margin-top: 40px;
      }
    }
  }
}

@media (min-width: 601px) {
  .cple_page {
    display: none;
  }

  .cple {
    width: 100%;
    background: #000000;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    .cple_con {
      width: 1000px;
      padding-top: 100px;

      h1 {
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 40px;
      }

      .cple_img {
        width: 1000px;
        height: 500px;
      }

      p {
        font-size: 18px;
        font-weight: 400;
        color: #ffffffb2;
        margin-bottom: 30px;
      }
    }
  }
}
</style>

