export default {
  关于: "에 대한",
  角色: "역할",
  NFT: "NFT",
  CPLE: "CPLE",
  发布行程: "여행 등록하기",
  购买: "구매",
  "PolloGo World 是一个结合了区块链技术与绿色出行理念的创新项目。它通过顺风车、步行和公共交通等多种方式，让用户不仅享受便捷的出行，还可以赚取CPLE代币奖励，实现经济收益。凭借其多样化的奖励机制、NFT 系统和长期的代币分配模型，PolloGo 为用户提供了一个独特的「边走边赚」的机会。展望未来，PolloGo World 有望成为区块链领域中出行类应用的先锋，吸引越来越多的用户加入并从中受益。在全球环境问题日益严重的背景下，PolloGo 的绿色出行理念也符合可持续发展的趋势，既为用户带来便利，又为环境保护贡献力量。":
    "PolloGo World는 블록체인 기술과 친환경 이동 개념을 결합한 혁신적인 프로젝트입니다. 카풀, 도보, 대중교통 등 다양한 방법을 통해 사용자에게 편리한 이동을 제공하고, CPLE 토큰 보상을 통해 경제적 수익을 얻을 수 있습니다. 다양한 보상 메커니즘, NFT 시스템 및 장기적인 토큰 분배 모델을 통해 PolloGo는 사용자에게 독특한 '걷는 동안 벌기'의 기회를 제공합니다. 미래를 바라보면, PolloGo World는 블록체인 분야의 이동 애플리케이션의 선두주자가 될 것으로 기대되며, 점점 더 많은 사용자가 참여하고 혜택을 받을 것입니다. 전 세계 환경 문제가 심각해짐에 따라 PolloGo의 친환경 이동 개념은 지속 가능한 발전 추세와 일치하며, 사용자에게 편리함을 제공하는 동시에 환경 보호에도 기여하고 있습니다.",
  "日常行程赚取 CPLE": "일상 일정으로 CPLE 벌기",
  "在 PolloGo 项目中，用户可以通过完成顺风车、步行或使用公共交通来赚取 CPLE 代币。行程的奖励基于“打卡点”机制，奖励取决于打卡点的数量和每个打卡点的奖励值。":
    "PolloGo 프로젝트에서 사용자는 카풀, 도보 또는 대중교통을 이용하여 CPLE 토큰을 벌 수 있습니다. 일정의 보상은 '체크인 포인트' 메커니즘에 기반하며, 보상은 체크인 포인트의 수와 각 체크인 포인트의 보상 값에 따라 결정됩니다.",
  单个行程的奖励规则: "단일 일정의 보상 규칙",
  "奖励计算公式：行程奖励 = 打卡点数量 × 单个打卡点奖励值":
    "보상 계산 공식: 일정 보상 = 체크인 포인트 수 × 단일 체크인 포인트 보상 값",
  "单个打卡点奖励值：公式：个人奖励基数 × (个人质押数量÷10,000)¹/⁴ 注意：每次行程的最高奖励按 70,000 的 N% 计算。":
    "단일 체크인 포인트 보상 값: 공식: 개인 보상 기준 × (개인 스테이킹 수량÷10,000)¹/⁴ \n주의: 각 일정의 최대 보상은 70,000의 N%로 계산됩니다.",
  顺风车司机: "카풀 드라이버",
  "每日可获得额外 2 个行程次数":
    "매일 추가로 2개의 일정 횟수를 얻을 수 있습니다.",
  出行达人: "여행 전문가",
  "每日可获得 5 个额外行程次数":
    "매일 추가로 5개의 일정 횟수를 얻을 수 있습니다.",
  车队老板: "차량 팀장",
  "每日可获得 100 个额外行程次数，可以将行程次数赠送给好友":
    "매일 추가로 100개의 일정 횟수를 얻을 수 있으며, 친구에게 일정 횟수를 선물할 수 있습니다.",
  城市合伙人: "도시 파트너",
  "每日可获得 1000 个额外行程次数，可以将行程次数赠送给好友":
    "매일 추가로 1000개의 일정 횟수를 얻을 수 있으며, 친구에게 일정 횟수를 선물할 수 있습니다.",
  车主汽车NFT: "차주 자동차 NFT",
  新星之驾: "스타 드라이버",
  银翼之旅: "은빛 날개 여행",
  金辉之路: "금빛 길",
  耀世之行: "빛나는 여정",
  传奇之旅: "전설적인 여행",
  非车主服饰NFT: "비차주 의상 NFT",
  普通款: "일반 버전",
  晨曦之装: "새벽의 옷",
  霞光之服: "여명 의상",
  月华之衣: "달빛의 의상",
  日曜之袍: "햇살의 로브",
  免费: "무료",
  打点奖励基数: "보상 기준",
  什么是CPLE: "CPLE란 무엇인가",
  "CPLE（Carpool Life Economy）是一种基于区块链的顺风车平台的Token，用于奖励用户并促进平台内的经济活动。目前由ITB PLE CO., LIMITED运营，代表项目有“趣出行”和“菠萝快跑”，并正尝试通过BTALK.AI与更多优质项目互相赋能来促进平台内的经济活动。":
    "CPLE (Carpool Life Economy)는 블록체인 기반의 카풀 플랫폼을 위한 토큰으로, 사용자를 보상하고 플랫폼 내의 경제 활동을 촉진하는 데 사용됩니다. 현재 ITB PLE CO., LIMITED에 의해 운영되며, '재미있는 여행'과 '파인애플 익스프레스'와 같은 프로젝트를 대표하고 있으며, BTALK.AI를 통해 더 많은 우수 프로젝트를 상호 지원하여 플랫폼 내의 경제 활동을 촉진하고자 하고 있습니다.",
  "在STON.FI上购买": "STON.FI에서 구매하기",
};
