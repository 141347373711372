export default {
  关于: "Về dự án",
  角色: "Vai trò",
  NFT: "NFT",
  CPLE: "CPLE",
  发布行程: "Đăng hành trình",
  购买: "Mua sắm",
  "PolloGo World 是一个结合了区块链技术与绿色出行理念的创新项目。它通过顺风车、步行和公共交通等多种方式，让用户不仅享受便捷的出行，还可以赚取CPLE代币奖励，实现经济收益。凭借其多样化的奖励机制、NFT 系统和长期的代币分配模型，PolloGo 为用户提供了一个独特的「边走边赚」的机会。展望未来，PolloGo World 有望成为区块链领域中出行类应用的先锋，吸引越来越多的用户加入并从中受益。在全球环境问题日益严重的背景下，PolloGo 的绿色出行理念也符合可持续发展的趋势，既为用户带来便利，又为环境保护贡献力量。":
    "PolloGo World là một dự án đổi mới kết hợp công nghệ blockchain và ý tưởng di chuyển xanh. Nó cho phép người dùng không chỉ tận hưởng sự di chuyển thuận tiện qua các hình thức như đi chung xe, đi bộ và giao thông công cộng, mà còn có thể kiếm được phần thưởng bằng token CPLE, đạt được lợi ích kinh tế. Nhờ vào cơ chế thưởng đa dạng, hệ thống NFT và mô hình phân phối token lâu dài, PolloGo cung cấp cho người dùng cơ hội độc đáo để ‘kiếm tiền khi di chuyển’. Nhìn về tương lai, PolloGo World có thể trở thành tiên phong trong lĩnh vực ứng dụng di chuyển trong blockchain, thu hút ngày càng nhiều người dùng tham gia và được hưởng lợi. Trong bối cảnh vấn đề môi trường toàn cầu ngày càng nghiêm trọng, ý tưởng di chuyển xanh của PolloGo cũng phù hợp với xu hướng phát triển bền vững, vừa mang lại tiện lợi cho người dùng, vừa góp phần bảo vệ môi trường.",
  "日常行程赚取 CPLE": "Kiếm CPLE từ những chuyến đi hàng ngày",
  "在 PolloGo 项目中，用户可以通过完成顺风车、步行或使用公共交通来赚取 CPLE 代币。行程的奖励基于“打卡点”机制，奖励取决于打卡点的数量和每个打卡点的奖励值。":
    "Trong dự án PolloGo, người dùng có thể kiếm token CPLE bằng cách thực hiện các chuyến đi chung xe, đi bộ hoặc sử dụng giao thông công cộng. Phần thưởng cho chuyến đi dựa trên cơ chế ‘điểm kiểm tra’, và phần thưởng phụ thuộc vào số lượng điểm và giá trị thưởng cho từng điểm.",
  单个行程的奖励规则: "Quy tắc thưởng cho một chuyến đi",
  "奖励计算公式：行程奖励 = 打卡点数量 × 单个打卡点奖励值":
    "Công thức tính thưởng: Phần thưởng cho chuyến đi = Số điểm kiểm tra × Giá trị thưởng cho một điểm kiểm tra",
  "单个打卡点奖励值：公式：个人奖励基数 × (个人质押数量÷10,000)¹/⁴ 注意：每次行程的最高奖励按 70,000 的 N% 计算。":
    "Giá trị thưởng cho một điểm kiểm tra: Công thức: Cơ sở thưởng cá nhân × (Số lượng đặt cược cá nhân ÷ 10,000)¹/⁴ \nLưu ý: Phần thưởng cao nhất cho mỗi chuyến đi được tính theo N% của 70,000.",
  顺风车司机: "Tài xế xe chung",
  "每日可获得额外 2 个行程次数": "Có thể nhận thêm 2 chuyến đi mỗi ngày.",
  出行达人: "Chuyên gia di chuyển",
  "每日可获得 5 个额外行程次数": "Có thể nhận thêm 5 chuyến đi mỗi ngày.",
  车队老板: "Chủ đội xe",
  "每日可获得 100 个额外行程次数，可以将行程次数赠送给好友":
    "Có thể nhận thêm 100 chuyến đi mỗi ngày và có thể tặng cho bạn bè.",
  城市合伙人: "Đối tác thành phố",
  "每日可获得 1000 个额外行程次数，可以将行程次数赠送给好友":
    "Có thể nhận thêm 1000 chuyến đi mỗi ngày và có thể tặng cho bạn bè.",
  车主汽车NFT: "NFT cho chủ xe",
  新星之驾: "Người lái xe ngôi sao",
  银翼之旅: "Chuyến đi Cánh Bạc",
  金辉之路: "Con đường Vàng",
  耀世之行: "Chuyến đi Rực Rỡ",
  传奇之旅: "Chuyến đi Huyền Thoại",
  非车主服饰NFT: "NFT trang phục cho không phải chủ xe",
  普通款: "Phiên bản tiêu chuẩn",
  晨曦之装: "Trang phục Bình Minh",
  霞光之服: "Áo Khoác Ánh Sáng",
  月华之衣: "Áo Đầm Trăng",
  日曜之袍: "Áo Choàng Nắng",
  免费: "Miễn phí",
  打点奖励基数: "Cơ sở thưởng",
  什么是CPLE: "CPLE là gì?",
  "CPLE（Carpool Life Economy）是一种基于区块链的顺风车平台的Token，用于奖励用户并促进平台内的经济活动。目前由ITB PLE CO., LIMITED运营，代表项目有“趣出行”和“菠萝快跑”，并正尝试通过BTALK.AI与更多优质项目互相赋能来促进平台内的经济活动。":
    "CPLE (Kinh tế Cuộc sống đi chung) là một token cho nền tảng đi chung xe dựa trên blockchain, được sử dụng để thưởng cho người dùng và thúc đẩy các hoạt động kinh tế trong nền tảng. Hiện tại, nó được quản lý bởi ITB PLE CO., LIMITED, đại diện cho các dự án như 'Chuyến đi Thú vị' và 'Chuyến xe Dứa', và đang cố gắng trao quyền cho nhiều dự án chất lượng hơn thông qua BTALK.AI để thúc đẩy các hoạt động kinh tế trong nền tảng.",
  "在STON.FI上购买": "Mua trên STON.FI",
};
