export default {
  关于: "Acerca de",
  角色: "Roles",
  NFT: "NFT",
  CPLE: "CPLE",
  发布行程: "Publicar viaje",
  购买: "Compra",
  "PolloGo World 是一个结合了区块链技术与绿色出行理念的创新项目。它通过顺风车、步行和公共交通等多种方式，让用户不仅享受便捷的出行，还可以赚取CPLE代币奖励，实现经济收益。凭借其多样化的奖励机制、NFT 系统和长期的代币分配模型，PolloGo 为用户提供了一个独特的「边走边赚」的机会。展望未来，PolloGo World 有望成为区块链领域中出行类应用的先锋，吸引越来越多的用户加入并从中受益。在全球环境问题日益严重的背景下，PolloGo 的绿色出行理念也符合可持续发展的趋势，既为用户带来便利，又为环境保护贡献力量。":
    "PolloGo World es un proyecto innovador que combina la tecnología blockchain con el concepto de transporte ecológico. A través de varias modalidades como el ridesharing, caminar y el transporte público, los usuarios no solo pueden disfrutar de un viaje conveniente, sino que también pueden ganar recompensas en tokens CPLE, logrando beneficios económicos. Con su diversa mecánica de recompensas, sistema de NFT y modelo de distribución de tokens a largo plazo, PolloGo ofrece a los usuarios una oportunidad única de 'ganar mientras se mueven'. De cara al futuro, PolloGo World aspira a ser un pionero en aplicaciones de transporte en el ámbito blockchain, atrayendo cada vez más usuarios para unirse y beneficiarse. En un contexto de problemas ambientales globales que se agravan, el concepto de transporte ecológico de PolloGo también se alinea con la tendencia del desarrollo sostenible, proporcionando comodidad a los usuarios y contribuyendo a la protección del medio ambiente.",
  "日常行程赚取 CPLE": "Ganar CPLE de viajes diarios",
  "在 PolloGo 项目中，用户可以通过完成顺风车、步行或使用公共交通来赚取 CPLE 代币。行程的奖励基于“打卡点”机制，奖励取决于打卡点的数量和每个打卡点的奖励值。":
    "En el proyecto PolloGo, los usuarios pueden ganar tokens CPLE completando ridesharing, caminando o utilizando el transporte público. Las recompensas por los viajes se basan en un mecanismo de 'puntos de control', y las recompensas dependen del número de puntos de control y del valor de recompensa de cada punto.",
  单个行程的奖励规则: "Reglas de recompensa para un solo viaje",
  "奖励计算公式：行程奖励 = 打卡点数量 × 单个打卡点奖励值":
    "Fórmula de cálculo de recompensas: Recompensa del viaje = Número de puntos de control × Valor de recompensa de un punto de control",
  "单个打卡点奖励值：公式：个人奖励基数 × (个人质押数量÷10,000)¹/⁴ 注意：每次行程的最高奖励按 70,000 的 N% 计算。":
    "Valor de recompensa de un solo punto de control: Fórmula: Base de recompensa personal × (Número de tokens apostados por persona ÷ 10,000)¹/⁴ \nNota: La recompensa máxima por cada viaje se calcula según el N% de 70,000.",
  顺风车司机: "Conductor de ridesharing",
  "每日可获得额外 2 个行程次数": "Puede obtener 2 viajes adicionales cada día.",
  出行达人: "Experto en viajes",
  "每日可获得 5 个额外行程次数": "Puede obtener 5 viajes adicionales cada día.",
  车队老板: "Propietario de flota",
  "每日可获得 100 个额外行程次数，可以将行程次数赠送给好友":
    "Puede obtener 100 viajes adicionales cada día y puede regalar esos viajes a amigos.",
  城市合伙人: "Socio de la ciudad",
  "每日可获得 1000 个额外行程次数，可以将行程次数赠送给好友":
    "Puede obtener 1000 viajes adicionales cada día y puede regalar esos viajes a amigos.",
  车主汽车NFT: "NFT de automóvil para propietarios",
  新星之驾: "Conductor Estrella",
  银翼之旅: "Viaje de Alas de Plata",
  金辉之路: "Camino de Brillo Dorado",
  耀世之行: "Viaje Brillante",
  传奇之旅: "Viaje Legendario",
  非车主服饰NFT: "NFT de vestimenta para no propietarios",
  普通款: "Edición estándar",
  晨曦之装: "Vestimenta del Amanecer",
  霞光之服: "Vestido de Luz del Crepúsculo",
  月华之衣: "Ropa de la Luna",
  日曜之袍: "Toga del Sol",
  免费: "Gratuito",
  打点奖励基数: "Base de recompensa",
  什么是CPLE: "¿Qué es CPLE?",
  "CPLE（Carpool Life Economy）是一种基于区块链的顺风车平台的Token，用于奖励用户并促进平台内的经济活动。目前由ITB PLE CO., LIMITED运营，代表项目有“趣出行”和“菠萝快跑”，并正尝试通过BTALK.AI与更多优质项目互相赋能来促进平台内的经济活动。":
    "CPLE (Economía de Vida de Covoiturage) es un token para una plataforma de ridesharing basada en blockchain, utilizado para recompensar a los usuarios y promover actividades económicas dentro de la plataforma. Actualmente, es operado por ITB PLE CO., LIMITED, representando proyectos como 'Viaje Divertido' y 'Pineapple Express', y está intentando empoderar más proyectos de calidad a través de BTALK.AI para promover actividades económicas dentro de la plataforma.",
  "在STON.FI上购买": "Comprar en STON.FI",
};
