<template>
  <div>
    <div class="footer">
      <div class="footer_hr"></div>

      <img src="../../assets/Frame 19.png" alt="" />
    </div>

    <div class="footer_page">
      <div class="footer_hr"></div>
      <div class="footer_page_con">
        <img src="../../assets/logo3.0 13.png" class="img1" alt="" />
        <img src="../../assets/TRIP IS MINING.png" class="img2" alt="" />
      </div>
    </div>
  </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
@media (max-width: 600px) {
  .footer {
    display: none;
  }

  .footer_page {
    width: 100%;
    height: 80px;
    background: #000000;

    .footer_hr {
      width: 95%;
      height: 1px;
      margin: 0 auto;
      background: #8390a3;
      opacity: 0.3;
      margin: 15px auto;
    }

    .footer_page_con {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 20px;

      .img1 {
        width: 118px;
        height: 44px;
      }

      .img2 {
        width: 127px;
        height: 16px;
      }
    }
  }
}

@media (min-width: 601px) {
  .footer_page {
    display: none;
  }

  .footer {
    width: 100%;
    height: 150px;
    background: #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .footer_hr {
      width: 95%;
      height: 1px;
      margin: 0 auto;
      background: #8390a3;
      opacity: 0.3;
    }

    img {
      width: 169px;
      height: 86px;
      margin-left: 50px;
      margin-top: 10px;
    }
  }
}
</style>