<template>
  <div>
    <div class="nft">
      <h1>{{ t("车主汽车NFT") }}</h1>

      <div class="nft-content">
        <div class="nft_child" v-for="(item, i) in translatedNftData" :key="i">
          <div class="nft_child_content">
            <img :src="item.url" alt="" class="nft_child_img" />
            <div class="nft_child_title">{{ item.translatedTitle }}</div>
          </div>

          <div class="nft_child_num-c">
            <div class="nft_child_num">
              <p>X</p>
              <p class="nft_child_num_p2">{{ item.num }}</p>
            </div>

            <p class="nft_child_num_p3">{{ t("打点奖励基数") }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="nft_page">
      <h1>{{ t("车主汽车NFT") }}</h1>

      <div class="nft-content">
        <div class="nft_child" v-for="(item, i) in translatedNftData" :key="i">
          <div class="nft_child_content">
            <img :src="item.url" alt="" class="nft_child_img" />
            <div class="nft_child_title">{{ item.translatedTitle }}</div>
          </div>

          <div class="nft_child_num-c">
            <div class="nft_child_num">
              <p style="margin-right: 2px">x</p>
              <p class="nft_child_num_p2">{{ item.num }}</p>
            </div>

            <p class="nft_child_num_p3">{{ t("打点奖励基数") }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { computed } from "vue";

const { t } = useI18n();

const nftData = [
  {
    url: require("@/assets/rembg_all_1727416390470.png"),
    num: "1.0",
    titleKey: "新星之驾",
  },
  {
    url: require("@/assets/rembg_all_1727416349708 1.png"),
    num: "1.2",
    titleKey: "银翼之旅",
  },
  {
    url: require("@/assets/rembg_all_1727416432271 1.png"),
    num: "1.5",
    titleKey: "金辉之路",
  },
  {
    url: require("@/assets/rembg_all_1727416457273 1.png"),
    num: "2.5",
    titleKey: "耀世之行",
  },
  {
    url: require("@/assets/rembg_all_1727416314481 1.png"),
    num: "4.0",
    titleKey: "传奇之旅",
  },
];

// 使用计算属性来获取翻译的标题
const translatedNftData = computed(() =>
  nftData.map((item) => ({
    ...item,
    translatedTitle: t(item.titleKey), // 根据 titleKey 获取翻译
  }))
);
</script>

<style lang="scss" scoped>
@media (max-width: 600px) {
  .nft {
    display: none;
  }

  .nft_page {
    display: block;
    width: 95%;
    margin: 80px auto;
    color: #fff;

    h1 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    .nft-content {
      width: calc(100vw - 30px);
      overflow-x: scroll;
      display: flex;
      justify-content: space-evenly;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE and Edge */

      &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, and Opera */
      }

      .nft_child {
        width: 135px;
        height: 198px;
        background: #22222866;
        border-radius: 8px;

        .nft_child_content {
          width: 131px;
          height: 150px;
          background: rgba(217, 217, 217, 0.1);
          border-radius: 8px;
          margin: 4px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          position: relative;

          .nft_child_img {
            width: 130px;
            height: 130px;
          }

          .nft_child_title {
            font-size: 10px;
            position: absolute;
            bottom: 10px;
            left: 10px;
          }
        }

        .nft_child_num-c {
          margin-top: 7px;
          margin-left: 15px;
          font-weight: 600;

          .nft_child_num {
            display: flex;
            align-items: flex-end;

            p {
              font-size: 9px;
            }

            .nft_child_num_p2 {
              font-size: 9px;
              font-weight: 600;
              line-height: 12px;
            }
          }

          .nft_child_num_p3 {
            font-size: 9px;
            font-weight: 400;
            color: #ffffff66;
            margin-top: 3px;
          }
        }
      }
    }
  }
}

@media (min-width: 601px) {
  .nft_page {
    display: none;
  }

  .nft {
    width: 100%;
    background: #000000;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px 0;

    h1 {
      font-size: 40px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 60px;
    }

    .nft-content {
      width: 100%;
      display: flex;
      justify-content: space-evenly;

      .nft_child {
        width: 270px;
        height: 370px;
        background: #22222866;
        border-radius: 8px;

        .nft_child_content {
          width: 262px;
          height: 275px;
          background: rgba(217, 217, 217, 0.1);
          border-radius: 8px;
          margin: 4px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          position: relative;

          .nft_child_img {
            width: 260px;
            height: 260px;
          }

          .nft_child_title {
            font-size: 20px;
            position: absolute;
            bottom: 10px;
            left: 20px;
          }
        }

        .nft_child_num-c {
          margin-top: 13px;
          margin-left: 15px;
          font-weight: 600;

          .nft_child_num {
            display: flex;
            align-items: flex-end;

            p {
              font-size: 18px;
            }

            .nft_child_num_p2 {
              font-size: 30px;
              line-height: 33px;
            }
          }

          .nft_child_num_p3 {
            font-size: 18px;
            font-weight: 400;
            color: #ffffff66;
            margin-top: 5px;
          }
        }
      }
    }
  }
}
</style>
