import { createI18n } from "vue-i18n";

import en from "../locales/en.js";
import zh from "../locales/zh.js";
import zhTW from "../locales/zh-TW.js";
import ja from "../locales/ja.js";
import ko from "../locales/ko.js";
import fr from "../locales/fr.js";
import en_PH from "../locales/en_PH.js";
import id from "../locales/id.js";
import es from "../locales/es.js";
import ru from "../locales/ru.js";
import vi from "../locales/vi.js";
import pt from "../locales/pt.js";

const messages = {
  en,
  zh: zh,
  zh_TW: zhTW,
  ja,
  ko,
  fr,
  en_PH: en_PH,
  id,
  es,
  ru,
  vi,
  pt,
};

const i18n = createI18n({
  locale: "zh", // 默认语言
  messages,
});

export default i18n;
