<template>
  <div>
    <div class="about">
      <img :src="getImageSrc" alt="" />
      <div class="about_txt">
        <h1>{{ t("关于") }}</h1>
        <div>
          <p>
            {{
              t(
                "PolloGo World 是一个结合了区块链技术与绿色出行理念的创新项目。它通过顺风车、步行和公共交通等多种方式，让用户不仅享受便捷的出行，还可以赚取CPLE代币奖励，实现经济收益。凭借其多样化的奖励机制、NFT 系统和长期的代币分配模型，PolloGo 为用户提供了一个独特的「边走边赚」的机会。展望未来，PolloGo World 有望成为区块链领域中出行类应用的先锋，吸引越来越多的用户加入并从中受益。在全球环境问题日益严重的背景下，PolloGo 的绿色出行理念也符合可持续发展的趋势，既为用户带来便利，又为环境保护贡献力量。"
              )
            }}
          </p>
        </div>
        <div>
          <h2>{{ t("日常行程赚取 CPLE") }}</h2>
          <p>
            {{
              t(
                "在 PolloGo 项目中，用户可以通过完成顺风车、步行或使用公共交通来赚取 CPLE 代币。行程的奖励基于“打卡点”机制，奖励取决于打卡点的数量和每个打卡点的奖励值。"
              )
            }}
          </p>
        </div>
        <div>
          <h2>{{ t("单个行程的奖励规则") }}</h2>
          <ul>
            <li>
              {{ t("奖励计算公式：行程奖励 = 打卡点数量 × 单个打卡点奖励值") }}
            </li>
            <li v-html="formattedText"></li>
          </ul>
        </div>
      </div>
    </div>

    <div class="about_page">
      <div class="about_page_img">
        <img :src="getImageSrc" alt="" />
      </div>

      <h1>{{ t("关于") }}</h1>
      <div>
        <p>
          {{
            t(
              "PolloGo World 是一个结合了区块链技术与绿色出行理念的创新项目。它通过顺风车、步行和公共交通等多种方式，让用户不仅享受便捷的出行，还可以赚取CPLE代币奖励，实现经济收益。凭借其多样化的奖励机制、NFT 系统和长期的代币分配模型，PolloGo 为用户提供了一个独特的「边走边赚」的机会。展望未来，PolloGo World 有望成为区块链领域中出行类应用的先锋，吸引越来越多的用户加入并从中受益。在全球环境问题日益严重的背景下，PolloGo 的绿色出行理念也符合可持续发展的趋势，既为用户带来便利，又为环境保护贡献力量。"
            )
          }}
        </p>
      </div>
      <div>
        <h2>{{ t("日常行程赚取 CPLE") }}</h2>
        <p>
          {{
            t(
              "在 PolloGo 项目中，用户可以通过完成顺风车、步行或使用公共交通来赚取 CPLE 代币。行程的奖励基于“打卡点”机制，奖励取决于打卡点的数量和每个打卡点的奖励值。"
            )
          }}
        </p>
      </div>
      <div>
        <h2>{{ t("单个行程的奖励规则") }}</h2>
        <ul>
          <li>
            {{ t("奖励计算公式：行程奖励 = 打卡点数量 × 单个打卡点奖励值") }}
          </li>
          <li v-html="formattedText"></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { computed } from "vue";
const { t, locale } = useI18n();

const getImageSrc = computed(() => {
  switch (locale.value) {
    case "zh":
      return require("../../assets/Frame 1321315028.png");
    case "zh_TW":
      return require("../../assets/繁体.png");
    case "en":
      return require("../../assets/英语.png");
    case "fr":
      return require("../../assets/法语.png");
    case "ja":
      return require("../../assets/日语.png");
    case "ko":
      return require("../../assets/韩语.png");
    case "en_PH":
      return require("../../assets/菲律宾语.png");
    case "id":
      return require("../../assets/印尼语.png");
    case "pt":
      return require("../../assets/葡萄牙语.png");
    case "ru":
      return require("../../assets/俄语.png");
    case "vi":
      return require("../../assets/越南语.png");
    case "es":
      return require("../../assets/西班牙语.png");
    default:
      return require("../../assets/Frame 1321315028.png");
  }
});

const formattedText = computed(() => {
  return t(
    "单个打卡点奖励值：公式：个人奖励基数 × (个人质押数量÷10,000)¹/⁴ 注意：每次行程的最高奖励按 70,000 的 N% 计算。"
  ).replace(/\n/g, "<br>");
});
</script>

<style lang="scss" scoped>
@media (max-width: 600px) {
  .about {
    display: none;
  }

  .about_page {
    display: block;
    color: #fff;
    width: 95%;
    margin: 20px auto;

    .about_page_img {
      display: flex;
      justify-content: center;

      img {
        width: 375px;
        height: 348px;
      }
    }

    h1 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    p,
    li {
      font-size: 15px;
      font-weight: 400;
      margin-bottom: 15px;
      text-align: justify;
    }

    h2 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    li {
      color: #ffffffb2;
      margin-left: 30px;
    }
  }
}

@media (min-width: 601px) {
  .about_page {
    display: none;
  }

  .about {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120vh;
    background: #000000;
    color: #fff;

    img {
      width: 721px;
      height: 668px;
    }

    .about_txt {
      width: 45%;
      font-weight: 600;
      margin-left: 40px;

      h1 {
        font-size: 40px;
        margin-bottom: 10px;
      }

      p,
      li {
        color: #ffffffb2;
        font-size: 18px;
        line-height: 30px;
      }

      h2 {
        font-size: 30px;
        font-weight: 500;
        margin: 15px 0;
      }

      ul {
        margin-left: 30px;
      }
    }
  }
}
</style>
