<template>
  <div>
    <div class="header">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        :ellipsis="false"
      >
        <el-menu-item index="0" class="no-hover" @click="scrollToTop">
          <img
            style="width: 100px"
            src="../../assets/logo3.0 12.png"
            alt="Element logo"
          />
        </el-menu-item>
        <el-menu-item
          v-for="(item, index) in menuItems"
          :key="index"
          :index="(index + 1).toString()"
          @click="navigate(item.label)"
        >
          {{ item.label }}
        </el-menu-item>
        <el-sub-menu index="2">
          <template #title>{{ currentLanguage }}</template>
          <el-menu-item
            v-for="(lang, index) in languages"
            :key="index"
            @click="setLanguage(lang.code)"
            :class="{ 'active-language': currentLanguage === lang.label }"
          >
            <span>{{ lang.label }}</span>
          </el-menu-item>
        </el-sub-menu>
      </el-menu>
    </div>

    <div class="header_page">
      <div class="header_page_box">
        <div class="header_page_logo">
          <img src="../../assets/logo3.0 12.png" alt="" class="img1" />
          <img
            src="../../assets/19.png"
            @click="drawer2 = true"
            alt=""
            class="img2"
          />
        </div>
      </div>
    </div>

    <el-drawer v-model="drawer2" :direction="direction">
      <template #header>
        <div class="logo_img">
          <img src="../../assets/logo3.0 12.png" alt="" />
        </div>
      </template>
      <template #default>
        <div class="drawer_locale">
          <p>语言</p>
        </div>

        <div>
          <div
            class="drawer_locale_con"
            v-for="(lang, index) in languages"
            :key="index"
            :class="{ selected: currentLanguage === lang.label }"
            @click="setLanguage(lang.code)"
          >
            <p>{{ lang.label }}</p>
            <img
              v-if="currentLanguage === lang.label"
              src="../../assets/20.png"
              alt=""
            />
          </div>
        </div>
      </template>
      <template #footer>
        <div class="buy_btn">
          <p @click="goTarget">{{ t("在STON.FI上购买") }}</p>
          <img src="../../assets/18.png" alt="" />
        </div>
      </template>
    </el-drawer>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { defineEmits } from "vue";

const emit = defineEmits();
const { t, locale } = useI18n();

const languages = [
  { code: "zh", label: "简体中文" },
  { code: "zh_TW", label: "繁体中文" },
  { code: "en", label: "English" },
  { code: "ja", label: "日本語" },
  { code: "ko", label: "한국어" },
  { code: "fr", label: "Français" },
  { code: "en_PH", label: "Pilipinas" },
  { code: "id", label: "Bahasa Indonesia" },
  { code: "es", label: "Español" },
  { code: "ru", label: "Русский" },
  { code: "vi", label: "Tiếng Việt" },
  { code: "pt", label: "Português" },
];

const menuItems = computed(() => [
  { label: t("关于") },
  { label: t("角色") },
  { label: t("NFT") },
  { label: t("CPLE") },
  { label: t("购买") },
]);

const currentLanguage = computed(() => {
  return languages.find((lang) => lang.code === locale.value)?.label || "中文";
});

const navigate = (label) => {
  emit("navigate", label);
};

const setLanguage = (language) => {
  locale.value = language;
  drawer2.value = false;
};

const drawer2 = ref(false);
const direction = ref("ttb");

onMounted(() => {
  const savedLanguage = localStorage.getItem("selectedLanguage");
  if (savedLanguage) {
    locale.value = savedLanguage;
  }
});

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

const goTarget = () => {
  const externalLink =
    "https://app.ston.fi/swap?chartVisible=false&chartInterval=1w&ft=EQDG_VDqIX2DqkjnRUrIh_rTMXPiwQgxsPgtzq0HX5D_ganI&fa=111&tt=USD%E2%82%AE"; // 替换为你的外部链接
  window.open(externalLink, "_blank");
};
</script>

<style lang="scss" scoped>
.el-menu {
  width: 100%;
  height: 68px;
  background: #000000cc;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1111;
  display: flex;
  align-items: center;
  color: #fff !important;
}
/* 定义当前激活语言的样式 */
.active-language {
  background: linear-gradient(90deg, #00ff80 0%, #f2ff5c 100%) !important;
  border-radius: 40px;
  height: 40px;
  color: var(--global-text-color) !important;
}

.el-menu--horizontal {
  .el-menu-item:nth-child(1) {
    margin-right: auto;
  }

  .el-menu-item.is-active {
    border: none;
    color: #fff;
  }

  .el-menu-item:not(.is-disabled):focus,
  .el-menu-item:not(.is-disabled):hover,
  ::v-deep .el-sub-menu .el-sub-menu__title:hover {
    background: linear-gradient(90deg, #00ff80 0%, #f2ff5c 100%) !important;
    border-radius: 40px;
    height: 40px;
    color: var(--global-text-color) !important;
  }

  .el-menu-item:active {
    color: #fff !important; // 点击时文字颜色保持为白色
  }
}

.el-menu--horizontal.el-menu,
::v-deep .el-menu--horizontal > .el-sub-menu.is-active .el-sub-menu__title {
  border: none;
}
.el-menu--horizontal > .el-menu-item {
  border: none;
  color: #fff;
  height: 40px;
}

.el-menu--horizontal > .el-menu-item.is-active,
::v-deep .el-menu--horizontal > .el-sub-menu .el-sub-menu__title {
  color: #fff !important;
}

.el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-sub-menu__title {
  height: 40px !important;
}

@media (max-width: 600px) {
  .header {
    display: none; /* 隐藏头部 */
  }

  .header_page {
    display: block;

    .header_page_box {
      width: 100%;
      background: #000000cc;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1111;

      .header_page_logo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 10px;

        .img1 {
          width: 118px;
          height: 44px;
        }

        .img2 {
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  ::v-deep .el-drawer {
    background: #000;
    height: 100vh !important;

    .el-drawer__header {
      padding: 10px 20px !important;
      margin-bottom: 0 !important;

      .logo_img {
        img {
          width: 118px;
          height: 44px;
        }
      }
    }

    .el-drawer__body {
      padding: 0 20px !important;
      .drawer_locale {
        p {
          font-size: 12px;
          font-weight: 400;
          color: #ffffff66;
        }
      }

      .drawer_locale_con {
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 20px;

        p {
          color: #ccc;
          font-size: 14px;
          font-weight: 400;
        }

        img {
          width: 12px;
          height: 12px;
        }
      }

      .drawer_locale_con.selected p {
        color: #fff;
      }
    }

    .el-drawer__footer {
      display: flex;
      justify-content: center;

      .buy_btn {
        height: 50px;
        background: #47ff75;
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 0 15px;

        img {
          width: 20px;
          height: 20px;
        }

        p {
          color: #202226;
        }
      }
    }
  }
}

@media (min-width: 601px) {
  .header_page {
    display: none; /* 在大屏幕下隐藏 header_page */
  }

  .header {
    display: block;
  }
}
</style>