export default {
  关于: "О проекте",
  角色: "Роли",
  NFT: "NFT",
  CPLE: "CPLE",
  发布行程: "Опубликовать поездку",
  购买: "Покупка",
  "PolloGo World 是一个结合了区块链技术与绿色出行理念的创新项目。它通过顺风车、步行和公共交通等多种方式，让用户不仅享受便捷的出行，还可以赚取CPLE代币奖励，实现经济收益。凭借其多样化的奖励机制、NFT 系统和长期的代币分配模型，PolloGo 为用户提供了一个独特的「边走边赚」的机会。展望未来，PolloGo World 有望成为区块链领域中出行类应用的先锋，吸引越来越多的用户加入并从中受益。在全球环境问题日益严重的背景下，PolloGo 的绿色出行理念也符合可持续发展的趋势，既为用户带来便利，又为环境保护贡献力量。":
    "PolloGo World — это инновационный проект, который сочетает технологии блокчейн и концепцию экологически чистого транспорта. С помощью различных способов, таких как совместные поездки, пешие прогулки и общественный транспорт, пользователи могут не только наслаждаться удобными поездками, но и зарабатывать токены CPLE в качестве вознаграждения, получая экономическую выгоду. Благодаря разнообразным механизмам вознаграждения, системе NFT и долгосрочной модели распределения токенов, PolloGo предоставляет пользователям уникальную возможность «заработать на ходу». В будущем PolloGo World может стать пионером в области приложений для транспорта в сфере блокчейн, привлекая все больше пользователей и позволяя им получать выгоду. В условиях нарастающих глобальных экологических проблем концепция экологически чистого транспорта PolloGo также соответствует тенденциям устойчивого развития, обеспечивая удобство для пользователей и внося вклад в защиту окружающей среды.",
  "日常行程赚取 CPLE": "Заработок CPLE от повседневных поездок",
  "在 PolloGo 项目中，用户可以通过完成顺风车、步行或使用公共交通来赚取 CPLE 代币。行程的奖励基于“打卡点”机制，奖励取决于打卡点的数量和每个打卡点的奖励值。":
    "В проекте PolloGo пользователи могут зарабатывать токены CPLE, выполняя совместные поездки, гуляя или используя общественный транспорт. Вознаграждения за поездки основаны на механизме «пунктов контроля», и сумма вознаграждений зависит от количества пунктов и значения вознаграждения за каждый пункт.",
  单个行程的奖励规则: "Правила вознаграждений за одну поездку",
  "奖励计算公式：行程奖励 = 打卡点数量 × 单个打卡点奖励值":
    "Формула расчета вознаграждений: Вознаграждение за поездку = Количество пунктов контроля × Вознаграждение за один пункт контроля",
  "单个打卡点奖励值：公式：个人奖励基数 × (个人质押数量÷10,000)¹/⁴ 注意：每次行程的最高奖励按 70,000 的 N% 计算。":
    "Значение вознаграждения за один пункт контроля: Формула: Базовое вознаграждение × (Количество токенов, ставленных пользователем ÷ 10,000)¹/⁴ \nПримечание: Максимальное вознаграждение за каждую поездку рассчитывается как N% от 70,000.",
  顺风车司机: "Водитель совместных поездок",
  "每日可获得额外 2 个行程次数":
    "Каждый день можно получить дополнительные 2 поездки.",
  出行达人: "Эксперт по поездкам",
  "每日可获得 5 个额外行程次数":
    "Каждый день можно получить дополнительные 5 поездок.",
  车队老板: "Владелец автопарка",
  "每日可获得 100 个额外行程次数，可以将行程次数赠送给好友":
    "Каждый день можно получить 100 дополнительных поездок, которые можно подарить друзьям.",
  城市合伙人: "Партнер по городу",
  "每日可获得 1000 个额外行程次数，可以将行程次数赠送给好友":
    "Каждый день можно получить 1000 дополнительных поездок, которые можно подарить друзьям.",
  车主汽车NFT: "NFT для владельцев автомобилей",
  新星之驾: "Звездный водитель",
  银翼之旅: "Путешествие Серебряного Крыла",
  金辉之路: "Золотой Путь",
  耀世之行: "Сияющее Путешествие",
  传奇之旅: "Легендарное Путешествие",
  非车主服饰NFT: "NFT одежды для не владельцев",
  普通款: "Стандартная версия",
  晨曦之装: "Утреннее Одеяние",
  霞光之服: "Одежда Рассвета",
  月华之衣: "Лунная Одежда",
  日曜之袍: "Солнечная Тога",
  免费: "Бесплатно",
  打点奖励基数: "Базовое вознаграждение",
  什么是CPLE: "Что такое CPLE?",
  "CPLE（Carpool Life Economy）是一种基于区块链的顺风车平台的Token，用于奖励用户并促进平台内的经济活动。目前由ITB PLE CO., LIMITED运营，代表项目有“趣出行”和“菠萝快跑”，并正尝试通过BTALK.AI与更多优质项目互相赋能来促进平台内的经济活动。":
    "CPLE (Экономика Совместных Поездок) — это токен для платформы совместных поездок, основанной на блокчейне, используемый для вознаграждения пользователей и содействия экономической деятельности на платформе. В настоящее время он управляется компанией ITB PLE CO., LIMITED, представляя такие проекты, как 'Увлекательные Поездки' и 'Ананасовый Экспресс', и пытается взаимодействовать с другими качественными проектами через BTALK.AI, чтобы содействовать экономической деятельности на платформе.",
  "在STON.FI上购买": "Купить на STON.FI",
};
