<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  font-family: "PingFang SC";
  font-weight: 500;
}

:root {
  --global-text-color: #0d0e0f;
  --global-text-color-w: #fff;
}
</style>