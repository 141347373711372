<template>
  <div>
    <div class="buy">
      <div class="gradient_radius box out">
        <div class="in">
          <div class="buy_con">
            <h1>{{ t("购买") }} {{ t("CPLE") }}</h1>
            <div class="buy_btn">
              <p @click="goTarget">{{ t("在STON.FI上购买") }}</p>
              <img src="../../assets/18.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="buy_page">
      <div class="gradient_radius box out">
        <div class="in">
          <div class="buy_con">
            <h1>{{ t("购买") }} {{ t("CPLE") }}</h1>
            <div class="buy_btn">
              <p @click="goTarget">{{ t("在STON.FI上购买") }}</p>
              <img src="../../assets/18.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const goTarget = () => {
  const externalLink =
    "https://app.ston.fi/swap?chartVisible=false&chartInterval=1w&ft=EQDG_VDqIX2DqkjnRUrIh_rTMXPiwQgxsPgtzq0HX5D_ganI&fa=111&tt=USD%E2%82%AE"; // 替换为你的外部链接
  window.open(externalLink, "_blank");
};
</script>

<style lang="scss" scoped>
@media (max-width: 600px) {
  .buy {
    display: none;
  }

  .buy_page {
    display: block;
    width: 95%;
    margin: 80px auto;
    color: #fff;

    .gradient_radius {
      width: 343px;
      height: 171px;
      margin: 0 auto;

      &.out {
        border-radius: 32px;
        padding: 1px;
        background: linear-gradient(
          107.88deg,
          rgba(0, 0, 0, 0) 8.12%,
          rgba(237, 255, 246, 0.77) 35.55%,
          rgba(249, 255, 211, 0.33) 64.51%,
          rgba(0, 0, 0, 0) 96.67%
        );
      }
      .in {
        width: 100%;
        height: 100%;
        background: #0e0e10;
        border-radius: 32px;

        .buy_con {
          width: 343px;
          height: 185px;
          background: url("../../assets/Ellipse 50.png") no-repeat;
          background-size: 100% 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          h1 {
            font-size: 28px;
            font-weight: 600;
            margin-bottom: 20px;
          }

          .buy_btn {
            height: 50px;
            background: #47ff75;
            border-radius: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            padding: 0 15px;

            img {
              width: 20px;
              height: 20px;
            }

            p {
              color: #202226;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 601px) {
  .buy_page {
    display: none;
  }

  .buy {
    width: 100%;
    background: #000000;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 200px 0;

    .box {
      width: 1000px;
      height: 330px;
      text-align: center;
      display: inline-grid;
    }

    .gradient_radius {
      &.out {
        border-radius: 32px;
        padding: 1px;
        background: linear-gradient(
          107.88deg,
          rgba(0, 0, 0, 0) 8.12%,
          rgba(237, 255, 246, 0.77) 35.55%,
          rgba(249, 255, 211, 0.33) 64.51%,
          rgba(0, 0, 0, 0) 96.67%
        );
      }
      .in {
        width: 100%;
        height: 100%;
        background: #0e0e10;
        border-radius: 32px;

        .buy_con {
          width: 1000px;
          height: 330px;
          background: url("../../assets/Ellipse 50.png") no-repeat;
          background-size: 100% 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          h1 {
            font-size: 40px;
            font-weight: 600;
            margin-bottom: 40px;
          }

          .buy_btn {
            height: 50px;
            background: #47ff75;
            border-radius: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            padding: 0 15px;

            img {
              width: 20px;
              height: 20px;
            }

            p {
              color: #202226;
            }
          }
        }
      }
    }
  }
}
</style>
