export default {
  关于: "Tentang",
  角色: "Peran",
  NFT: "NFT",
  CPLE: "CPLE",
  发布行程: "Menerbitkan perjalanan",
  购买: "Beli",
  "PolloGo World 是一个结合了区块链技术与绿色出行理念的创新项目。它通过顺风车、步行和公共交通等多种方式，让用户不仅享受便捷的出行，还可以赚取CPLE代币奖励，实现经济收益。凭借其多样化的奖励机制、NFT 系统和长期的代币分配模型，PolloGo 为用户提供了一个独特的「边走边赚」的机会。展望未来，PolloGo World 有望成为区块链领域中出行类应用的先锋，吸引越来越多的用户加入并从中受益。在全球环境问题日益严重的背景下，PolloGo 的绿色出行理念也符合可持续发展的趋势，既为用户带来便利，又为环境保护贡献力量。":
    "PolloGo World adalah proyek inovatif yang menggabungkan teknologi blockchain dengan konsep transportasi ramah lingkungan. Melalui berbagai cara seperti ridesharing, berjalan kaki, dan transportasi umum, pengguna tidak hanya dapat menikmati perjalanan yang nyaman tetapi juga dapat menghasilkan imbalan token CPLE, yang menghasilkan manfaat ekonomi. Dengan berbagai mekanisme imbalan, sistem NFT, dan model distribusi token jangka panjang, PolloGo menawarkan kesempatan unik bagi pengguna untuk 'menghasilkan sambil bergerak'. Melihat ke depan, PolloGo World diharapkan menjadi pelopor aplikasi transportasi di bidang blockchain, menarik semakin banyak pengguna untuk bergabung dan mendapatkan manfaat. Di tengah masalah lingkungan global yang semakin serius, konsep transportasi ramah lingkungan PolloGo juga sejalan dengan tren pembangunan berkelanjutan, yang memberikan kenyamanan bagi pengguna dan berkontribusi pada perlindungan lingkungan.",
  "日常行程赚取 CPLE": "Dapatkan CPLE dari perjalanan sehari-hari",
  "在 PolloGo 项目中，用户可以通过完成顺风车、步行或使用公共交通来赚取 CPLE 代币。行程的奖励基于“打卡点”机制，奖励取决于打卡点的数量和每个打卡点的奖励值。":
    "Dalam proyek PolloGo, pengguna dapat menghasilkan token CPLE dengan menyelesaikan ridesharing, berjalan kaki, atau menggunakan transportasi umum. Imbalan untuk perjalanan didasarkan pada mekanisme 'titik kontrol', dan imbalan bergantung pada jumlah titik kontrol dan nilai imbalan dari setiap titik kontrol.",
  单个行程的奖励规则: "Aturan imbalan untuk satu perjalanan",
  "奖励计算公式：行程奖励 = 打卡点数量 × 单个打卡点奖励值":
    "Rumus perhitungan imbalan: Imbalan perjalanan = Jumlah titik kontrol × Nilai imbalan dari satu titik kontrol",
  "单个打卡点奖励值：公式：个人奖励基数 × (个人质押数量÷10,000)¹/⁴ 注意：每次行程的最高奖励按 70,000 的 N% 计算。":
    "Nilai imbalan dari satu titik kontrol: Rumus: Dasar imbalan pribadi × (Jumlah token yang dipertaruhkan pribadi ÷ 10.000)¹/⁴ \nCatatan: Imbalan tertinggi untuk setiap perjalanan dihitung berdasarkan N% dari 70.000.",
  顺风车司机: "Pengemudi ridesharing",
  "每日可获得额外 2 个行程次数": "Dapatkan 2 perjalanan tambahan setiap hari.",
  出行达人: "Ahli perjalanan",
  "每日可获得 5 个额外行程次数": "Dapatkan 5 perjalanan tambahan setiap hari.",
  车队老板: "Pemilik armada",
  "每日可获得 100 个额外行程次数，可以将行程次数赠送给好友":
    "Dapatkan 100 perjalanan tambahan setiap hari dan dapat memberikan perjalanan tersebut kepada teman.",
  城市合伙人: "Mitra kota",
  "每日可获得 1000 个额外行程次数，可以将行程次数赠送给好友":
    "Dapatkan 1000 perjalanan tambahan setiap hari dan dapat memberikan perjalanan tersebut kepada teman.",
  车主汽车NFT: "NFT mobil untuk pemilik",
  新星之驾: "Pengemudi Bintang",
  银翼之旅: "Perjalanan Sayap Perak",
  金辉之路: "Jalan Cahaya Emas",
  耀世之行: "Perjalanan Berkilau",
  传奇之旅: "Perjalanan Legendaris",
  非车主服饰NFT: "NFT pakaian untuk non-pemilik",
  普通款: "Edisi Standar",
  晨曦之装: "Pakaian Fajar",
  霞光之服: "Pakaian Sinar Senja",
  月华之衣: "Pakaian Bulan",
  日曜之袍: "Jubah Matahari",
  免费: "Gratis",
  打点奖励基数: "Dasar imbalan",
  什么是CPLE: "Apa itu CPLE",
  "CPLE（Carpool Life Economy）是一种基于区块链的顺风车平台的Token，用于奖励用户并促进平台内的经济活动。目前由ITB PLE CO., LIMITED运营，代表项目有“趣出行”和“菠萝快跑”，并正尝试通过BTALK.AI与更多优质项目互相赋能来促进平台内的经济活动。":
    "CPLE (Carpool Life Economy) adalah token untuk platform ridesharing yang berbasis blockchain, digunakan untuk memberi imbalan kepada pengguna dan mempromosikan aktivitas ekonomi di dalam platform. Saat ini, dikelola oleh ITB PLE CO., LIMITED, yang mewakili proyek-proyek seperti 'Perjalanan Menyenangkan' dan 'Pineapple Express', dan berusaha memberdayakan lebih banyak proyek berkualitas melalui BTALK.AI untuk mempromosikan aktivitas ekonomi di dalam platform.",
  "在STON.FI上购买": "Beli di STON.FI",
};
