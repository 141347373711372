export default {
  关于: "About",
  角色: "Roles",
  NFT: "NFT",
  CPLE: "CPLE",
  发布行程: "Publish Trip",
  购买: "Buy",
  "PolloGo World 是一个结合了区块链技术与绿色出行理念的创新项目。它通过顺风车、步行和公共交通等多种方式，让用户不仅享受便捷的出行，还可以赚取CPLE代币奖励，实现经济收益。凭借其多样化的奖励机制、NFT 系统和长期的代币分配模型，PolloGo 为用户提供了一个独特的「边走边赚」的机会。展望未来，PolloGo World 有望成为区块链领域中出行类应用的先锋，吸引越来越多的用户加入并从中受益。在全球环境问题日益严重的背景下，PolloGo 的绿色出行理念也符合可持续发展的趋势，既为用户带来便利，又为环境保护贡献力量。":
    "PolloGo World is an innovative project that combines blockchain technology with the concept of green travel. It allows users to enjoy convenient transportation through various means such as ride-sharing, walking, and public transport, while earning CPLE token rewards for economic benefits. With its diversified reward mechanisms, NFT system, and long-term token distribution model, PolloGo offers users a unique opportunity to 'earn while moving.' Looking ahead, PolloGo World is expected to become a pioneer in the blockchain field for travel applications, attracting more and more users to join and benefit. In the context of increasingly severe global environmental issues, PolloGo's green travel concept aligns with the trend of sustainable development, providing convenience for users while contributing to environmental protection.",
  "日常行程赚取 CPLE": "Earn CPLE through Daily Trips",
  "在 PolloGo 项目中，用户可以通过完成顺风车、步行或使用公共交通来赚取 CPLE 代币。行程的奖励基于“打卡点”机制，奖励取决于打卡点的数量和每个打卡点的奖励值。":
    "In the PolloGo project, users can earn CPLE tokens by completing ride-sharing, walking, or using public transport. The rewards for trips are based on the 'check-in point' mechanism, where the rewards depend on the number of check-in points and the reward value for each check-in point.",
  单个行程的奖励规则: "Single Trip Reward Rules",
  "奖励计算公式：行程奖励 = 打卡点数量 × 单个打卡点奖励值":
    "Reward Calculation Formula: Trip Reward = Number of Check-in Points × Reward Value per Check-in Point",
  "单个打卡点奖励值：公式：个人奖励基数 × (个人质押数量÷10,000)¹/⁴ 注意：每次行程的最高奖励按 70,000 的 N% 计算。":
    "Reward Value per Check-in Point: Formula: Personal Reward Base × (Personal Staked Amount ÷ 10,000)¹/⁴ \nNote: The maximum reward for each trip is calculated based on N% of 70,000.",
  顺风车司机: "Ride-sharing Drivers",
  "每日可获得额外 2 个行程次数": "Can earn 2 extra trip counts daily",
  出行达人: "Travel Experts",
  "每日可获得 5 个额外行程次数": "Can earn 5 extra trip counts daily",
  车队老板: "Fleet Owners",
  "每日可获得 100 个额外行程次数，可以将行程次数赠送给好友":
    "Can earn 100 extra trip counts daily and gift trip counts to friends",
  城市合伙人: "City Partners",
  "每日可获得 1000 个额外行程次数，可以将行程次数赠送给好友":
    "Can earn 1000 extra trip counts daily and gift trip counts to friends",
  车主汽车NFT: "Car Owner Vehicle NFTs",
  新星之驾: "Star Driver",
  银翼之旅: "Silver Wing Journey",
  金辉之路: "Golden Glory Road",
  耀世之行: "Radiant World Journey",
  传奇之旅: "Legendary Journey",
  非车主服饰NFT: "Non-Car Owner Clothing NFTs",
  普通款: "Standard Edition",
  晨曦之装: "Dawn Attire",
  霞光之服: "Dawn Light Outfit",
  月华之衣: "Moonlight Garment",
  日曜之袍: "Sunshine Robe",
  免费: "Free",
  打点奖励基数: "Check-in Reward Base",
  什么是CPLE: "What is CPLE?",
  "CPLE（Carpool Life Economy）是一种基于区块链的顺风车平台的Token，用于奖励用户并促进平台内的经济活动。目前由ITB PLE CO., LIMITED运营，代表项目有“趣出行”和“菠萝快跑”，并正尝试通过BTALK.AI与更多优质项目互相赋能来促进平台内的经济活动。":
    "CPLE (Carpool Life Economy) is a token for a blockchain-based ridesharing platform, used to reward users and promote economic activities within the platform. It is currently operated by ITB PLE CO., LIMITED, representing projects like 'Fun Travel' and 'Pineapple Express', and is trying to empower more quality projects through BTALK.AI to promote economic activities within the platform.",
  "在STON.FI上购买": "Buy on STON.FI",
};
