export default {
  关于: "Sobre",
  角色: "Papéis",
  NFT: "NFT",
  CPLE: "CPLE",
  发布行程: "Publicar viagem",
  购买: "Comprar",
  "PolloGo World 是一个结合了区块链技术与绿色出行理念的创新项目。它通过顺风车、步行和公共交通等多种方式，让用户不仅享受便捷的出行，还可以赚取CPLE代币奖励，实现经济收益。凭借其多样化的奖励机制、NFT 系统和长期的代币分配模型，PolloGo 为用户提供了一个独特的「边走边赚」的机会。展望未来，PolloGo World 有望成为区块链领域中出行类应用的先锋，吸引越来越多的用户加入并从中受益。在全球环境问题日益严重的背景下，PolloGo 的绿色出行理念也符合可持续发展的趋势，既为用户带来便利，又为环境保护贡献力量。":
    "PolloGo World é um projeto inovador que combina tecnologia blockchain com a ideia de transporte verde. Ele permite que os usuários não apenas desfrutem de deslocamentos convenientes por meio de caronas, caminhadas e transporte público, mas também ganhem recompensas em tokens CPLE, alcançando benefícios econômicos. Com sua diversidade de mecanismos de recompensa, sistema de NFT e modelo de distribuição de tokens a longo prazo, o PolloGo oferece aos usuários uma oportunidade única de 'ganhar enquanto se deslocam'. Olhando para o futuro, o PolloGo World tem potencial para se tornar um pioneiro em aplicativos de transporte no campo do blockchain, atraindo cada vez mais usuários a se juntarem e se beneficiarem. Em um contexto de problemas ambientais globais crescentes, a filosofia de transporte verde do PolloGo também se alinha com a tendência de desenvolvimento sustentável, trazendo conveniência para os usuários e contribuindo para a proteção ambiental.",
  "日常行程赚取 CPLE": "Ganhar CPLE com viagens diárias",
  "在 PolloGo 项目中，用户可以通过完成顺风车、步行或使用公共交通来赚取 CPLE 代币。行程的奖励基于“打卡点”机制，奖励取决于打卡点的数量和每个打卡点的奖励值。":
    "No projeto PolloGo, os usuários podem ganhar tokens CPLE completando caronas, caminhadas ou usando transporte público. As recompensas das viagens são baseadas no mecanismo de 'pontos de verificação', e as recompensas dependem do número de pontos e do valor de recompensa para cada ponto.",
  单个行程的奖励规则: "Regras de recompensa para uma viagem única",
  "奖励计算公式：行程奖励 = 打卡点数量 × 单个打卡点奖励值":
    "Fórmula de cálculo de recompensa: Recompensa da viagem = Número de pontos de verificação × Valor da recompensa por ponto de verificação",
  "单个打卡点奖励值：公式：个人奖励基数 × (个人质押数量÷10,000)¹/⁴ 注意：每次行程的最高奖励按 70,000 的 N% 计算。":
    "Valor da recompensa por ponto de verificação: Fórmula: Base de recompensa pessoal × (Quantidade apostada pessoal ÷ 10.000)¹/⁴ \nNota: A recompensa máxima para cada viagem é calculada como N% de 70.000.",
  顺风车司机: "Motorista de carona",
  "每日可获得额外 2 个行程次数": "Pode receber 2 viagens extras por dia.",
  出行达人: "Expert em transporte",
  "每日可获得 5 个额外行程次数": "Pode receber 5 viagens extras por dia.",
  车队老板: "Donos de frota",
  "每日可获得 100 个额外行程次数，可以将行程次数赠送给好友":
    "Pode receber 100 viagens extras por dia e pode presentear amigos com essas viagens.",
  城市合伙人: "Parceiro urbano",
  "每日可获得 1000 个额外行程次数，可以将行程次数赠送给好友":
    "Pode receber 1000 viagens extras por dia e pode presentear amigos com essas viagens.",
  车主汽车NFT: "NFT para proprietários de veículos",
  新星之驾: "Motorista Estrela",
  银翼之旅: "Viagem Asas Prateadas",
  金辉之路: "Caminho Dourado",
  耀世之行: "Viagem Brilhante",
  传奇之旅: "Viagem Lendária",
  非车主服饰NFT: "NFT de vestuário para não proprietários",
  普通款: "Edição Padrão",
  晨曦之装: "Vestimenta do Amanhecer",
  霞光之服: "Roupa do Crepúsculo",
  月华之衣: "Vestido da Luz da Lua",
  日曜之袍: "Manto do Sol",
  免费: "Grátis",
  打点奖励基数: "Base de recompensa",
  什么是CPLE: "O que é CPLE?",
  "CPLE（Carpool Life Economy）是一种基于区块链的顺风车平台的Token，用于奖励用户并促进平台内的经济活动。目前由ITB PLE CO., LIMITED运营，代表项目有“趣出行”和“菠萝快跑”，并正尝试通过BTALK.AI与更多优质项目互相赋能来促进平台内的经济活动。":
    "CPLE (Economia de Vida de Carona) é um token para uma plataforma de carona baseada em blockchain, utilizado para recompensar os usuários e promover atividades econômicas dentro da plataforma. Atualmente, é operado pela ITB PLE CO., LIMITED, representando projetos como 'Viagem Divertida' e 'Pineapple Express', e está tentando capacitar mais projetos de qualidade por meio do BTALK.AI para promover atividades econômicas dentro da plataforma.",
  "在STON.FI上购买": "Comprar na STON.FI",
};
