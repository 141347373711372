export default {
  关于: "À propos",
  角色: "Rôle",
  NFT: "NFT",
  CPLE: "CPLE",
  发布行程: "Publier un voyage",
  购买: "Achat",
  "PolloGo World 是一个结合了区块链技术与绿色出行理念的创新项目。它通过顺风车、步行和公共交通等多种方式，让用户不仅享受便捷的出行，还可以赚取CPLE代币奖励，实现经济收益。凭借其多样化的奖励机制、NFT 系统和长期的代币分配模型，PolloGo 为用户提供了一个独特的「边走边赚」的机会。展望未来，PolloGo World 有望成为区块链领域中出行类应用的先锋，吸引越来越多的用户加入并从中受益。在全球环境问题日益严重的背景下，PolloGo 的绿色出行理念也符合可持续发展的趋势，既为用户带来便利，又为环境保护贡献力量。":
    "PolloGo World est un projet innovant qui allie la technologie blockchain et le concept de mobilité verte. Grâce à des moyens variés tels que le covoiturage, la marche et les transports publics, les utilisateurs peuvent non seulement profiter d'un transport pratique, mais aussi gagner des récompenses en tokens CPLE, réalisant ainsi des bénéfices économiques. Grâce à son mécanisme de récompense diversifié, son système NFT et son modèle de distribution de tokens à long terme, PolloGo offre aux utilisateurs une occasion unique de « gagner en se déplaçant ». À l'avenir, PolloGo World devrait devenir un pionnier des applications de mobilité dans le domaine de la blockchain, attirant de plus en plus d'utilisateurs et leur permettant d'en bénéficier. Dans un contexte de problèmes environnementaux mondiaux de plus en plus graves, le concept de mobilité verte de PolloGo correspond également à la tendance du développement durable, apportant à la fois commodité aux utilisateurs et contribuant à la protection de l'environnement.",
  "日常行程赚取 CPLE": "Gagner des CPLE lors des trajets quotidiens",
  "在 PolloGo 项目中，用户可以通过完成顺风车、步行或使用公共交通来赚取 CPLE 代币。行程的奖励基于“打卡点”机制，奖励取决于打卡点的数量和每个打卡点的奖励值。":
    "Dans le projet PolloGo, les utilisateurs peuvent gagner des tokens CPLE en effectuant des trajets en covoiturage, en marchant ou en utilisant les transports publics. Les récompenses des trajets sont basées sur un mécanisme de « points de contrôle », et dépendent du nombre de points de contrôle et de la valeur de la récompense de chaque point de contrôle.",
  单个行程的奖励规则: "Règles de récompense pour un trajet unique",
  "奖励计算公式：行程奖励 = 打卡点数量 × 单个打卡点奖励值":
    "Formule de calcul des récompenses : Récompense du trajet = Nombre de points de contrôle × Valeur de récompense d'un point de contrôle",
  "单个打卡点奖励值：公式：个人奖励基数 × (个人质押数量÷10,000)¹/⁴ 注意：每次行程的最高奖励按 70,000 的 N% 计算。":
    "Valeur de récompense d'un point de contrôle : Formule : Base de récompense personnelle × (Nombre de tokens mis en jeu personnel ÷ 10 000)¹/⁴ \nRemarque : La récompense maximale pour chaque trajet est calculée selon N% de 70 000.",
  顺风车司机: "Conducteur de covoiturage",
  "每日可获得额外 2 个行程次数":
    "Peut obtenir 2 trajets supplémentaires par jour.",
  出行达人: "Expert en déplacements",
  "每日可获得 5 个额外行程次数":
    "Peut obtenir 5 trajets supplémentaires par jour.",
  车队老板: "Propriétaire de flotte",
  "每日可获得 100 个额外行程次数，可以将行程次数赠送给好友":
    "Peut obtenir 100 trajets supplémentaires par jour et peut les offrir à des amis.",
  城市合伙人: "Partenaire urbain",
  "每日可获得 1000 个额外行程次数，可以将行程次数赠送给好友":
    "Peut obtenir 1000 trajets supplémentaires par jour et peut les offrir à des amis.",
  车主汽车NFT: "NFT de voiture pour propriétaire",
  新星之驾: "Conducteur étoile",
  银翼之旅: "Voyage des ailes d'argent",
  金辉之路: "Chemin doré",
  耀世之行: "Voyage éclatant",
  传奇之旅: "Voyage légendaire",
  非车主服饰NFT: "NFT de vêtements pour non-propriétaires",
  普通款: "Édition standard",
  晨曦之装: "Vêtements de l'aube",
  霞光之服: "Vêtements du crépuscule",
  月华之衣: "Vêtements de la lune",
  日曜之袍: "Robe du soleil",
  免费: "Gratuit",
  打点奖励基数: "Base de récompense",
  什么是CPLE: "Qu'est-ce que CPLE",
  "CPLE（Carpool Life Economy）是一种基于区块链的顺风车平台的Token，用于奖励用户并促进平台内的经济活动。目前由ITB PLE CO., LIMITED运营，代表项目有“趣出行”和“菠萝快跑”，并正尝试通过BTALK.AI与更多优质项目互相赋能来促进平台内的经济活动。":
    "CPLE (Carpool Life Economy) est un token pour une plateforme de covoiturage basée sur blockchain, utilisé pour récompenser les utilisateurs et promouvoir les activités économiques au sein de la plateforme. Actuellement, il est géré par ITB PLE CO., LIMITED, représentant des projets tels que 'Voyage Amusant' et 'Pineapple Express', et tente d'autonomiser davantage de projets de qualité grâce à BTALK.AI pour promouvoir les activités économiques au sein de la plateforme.",
  "在STON.FI上购买": "Acheter sur STON.FI",
};
