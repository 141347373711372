<template>
  <div class="container" @scroll="handleScroll">
    <Header @navigate="scrollToSection" />

    <div>
      <div class="home">
        <img src="@/assets/Frame 1321314995.png" alt="" />
        <div class="erweima">
          <div class="erweima_tele" @click="goTarget">
            <img src="../../assets/telegram 1.png" alt="" />
            <div style="padding: 0 5px">
              <p>Telegram miniapp</p>
              <h3>{{ t("发布行程") }}</h3>
            </div>
          </div>

          <div class="erweima_img">
            <img src="../../assets/image 365.png" alt="" />

            <div class="erweima_hover">
              <img src="../../assets/image 365 (1).png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="home_page">
        <div class="header_page_img">
          <img src="../../assets/Frame 1321314996.png" alt="" />
        </div>
        <div class="erweima">
          <div class="erweima_tele" @click="goTarget">
            <img src="../../assets/telegram 1.png" alt="" />
            <div style="padding: 0 5px">
              <p>Telegram miniapp</p>
              <h3>{{ t("发布行程") }}</h3>
            </div>
          </div>
          <div class="erweima_img">
            <img src="../../assets/image 365 (1).png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div ref="aboutSection">
      <About />
    </div>
    <div ref="roleSection">
      <Role />
    </div>
    <div ref="nftSection">
      <NFT />
    </div>
    <div ref="nftNotSection">
      <NFTNot />
    </div>
    <div ref="cpleSection">
      <CPLE />
    </div>
    <div ref="buySection">
      <Buy />
    </div>

    <Footer />
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

import Header from "@/components/Header";
import About from "@/views/About";
import Role from "@/views/Role";
import NFT from "@/views/NFT";
import NFTNot from "@/views/NFT/not.vue";
import CPLE from "@/views/CPLE";
import Buy from "@/views/Buy";
import Footer from "@/components/Footer";

const aboutSection = ref(null);
const roleSection = ref(null);
const nftSection = ref(null);
const nftNotSection = ref(null);
const cpleSection = ref(null);
const buySection = ref(null);

const scrollToSection = (section) => {
  switch (section) {
    case t("关于"):
      aboutSection.value.scrollIntoView({ behavior: "smooth" });
      break;
    case t("角色"):
      roleSection.value.scrollIntoView({ behavior: "smooth" });
      break;
    case t("NFT"):
      nftSection.value.scrollIntoView({ behavior: "smooth" });
      break;
    case t("CPLE"):
      cpleSection.value.scrollIntoView({ behavior: "smooth" });
      break;
    case t("购买"):
      buySection.value.scrollIntoView({ behavior: "smooth" });
      break;
  }
};

const goTarget = () => {
  const externalLink = "https://t.me/PollogoBot";
  window.open(externalLink, "_blank");
};
</script>

<style lang="scss" scoped>
.container {
  height: 100vh;
  overflow-y: auto; /* 允许垂直滚动 */
}

.home {
  width: 100%;
  height: 100vh;
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }

  .erweima {
    width: 330px;
    height: 112px;
    position: absolute;
    top: calc(50% - 18px);
    left: calc(50% - 200px);
    background: #ffffff80;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .erweima_tele,
    .erweima_img,
    .erweima_hover {
      display: flex;
      align-items: center;
      border-radius: 16px;
      background: #fff;
    }

    .erweima_tele {
      justify-content: space-evenly;
      height: 64px;
      padding: 0 10px;
      cursor: pointer;

      img {
        width: 35px;
        height: 35px;
      }

      p {
        font-size: 12px;
        margin-bottom: 5px;
      }

      h3 {
        font-size: 16px;
      }
    }

    .erweima_img {
      width: 64px;
      height: 64px;
      justify-content: center;
      position: relative;

      img {
        width: 42px;
        height: 42px;
      }

      .erweima_hover {
        width: 156px;
        height: 156px;
        justify-content: center;
        display: none !important; /* 默认不展示 */
        position: absolute; /* 可以根据需要调整位置 */
        top: 73px; /* 根据需要调整位置 */
        left: 0; /* 根据需要调整位置 */
        z-index: 10; /* 确保在其他元素之上 */
        opacity: 0; /* 默认透明 */
        transition: opacity 0.3s ease; /* 添加动画效果 */

        img {
          width: 140px;
          height: 140px;
        }
      }

      &:hover .erweima_hover {
        display: flex !important; /* 鼠标移入时显示 */
        opacity: 1; /* 渐显 */
      }
    }
  }
}

@media (max-width: 600px) {
  .home,
  .about,
  .role,
  .nft,
  .nft-not,
  .buy,
  .cple,
  .footer {
    display: none;
  }

  .home_page {
    .header_page_img {
      img {
        width: 100%;
        height: 275px;
      }
    }

    .erweima {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .erweima_tele {
        height: 64px;
        background: linear-gradient(90deg, #00ff80 0%, #f2ff5c 100%);
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        margin-top: 30px;
        padding: 0 10px;

        img {
          width: 35px;
          height: 35px;
        }

        p {
          font-size: 12px;
          margin-bottom: 5px;
        }

        h3 {
          font-size: 16px;
        }
      }

      .erweima_img {
        width: 145px;
        height: 145px;
        background: #fff;
        margin-top: 20px;
        border-radius: 14px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 140px;
          height: 140px;
          border-radius: 14px;
        }
      }
    }
  }

  .container {
    height: 100vh;
    overflow-y: scroll; /* 允许垂直滚动 */
    background: #000000;

    .home_page,
    .about_page,
    .role_page {
      display: block;
    }
  }
}

@media (min-width: 601px) {
  .header_page,
  .home_page,
  .about_page,
  .role_page,
  .cple_page,
  .buy_page,
  .nft-not_page,
  .footer_page {
    display: none; /* 在大屏幕下隐藏 header_page */
  }

  .home,
  .about,
  .role,
  .nft,
  .nft-not {
    display: block; /* 在大屏幕下显示其他组件 */
  }

  .buy,
  .cple,
  .footer {
    display: flex; /* 在大屏幕下显示其他组件 */
  }
}
</style>
