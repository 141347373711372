<template>
  <div>
    <div class="role">
      <div class="role-bg">
        <h1>{{ t("角色") }}</h1>

        <div class="role_bg_child">
          <div class="role_child">
            <img src="../../assets/Component 370.png" alt="" />
            <h2
              :style="{
                fontSize: t('lang') === 'Pilipinas' ? '30px' : '26px',
              }"
            >
              {{ t("顺风车司机") }}
            </h2>
            <div
              class="role_hr1"
              :style="{
                marginTop: t('lang') === 'Pilipinas' ? '20px' : '12px',
              }"
            ></div>
            <p
              :style="{
                fontSize: t('lang') === 'Pilipinas' ? '18px' : '15px',
              }"
            >
              {{ t("每日可获得额外 2 个行程次数") }}
            </p>
          </div>
          <div class="role_child2">
            <img src="../../assets/Component 370 (1).png" alt="" />
            <h2
              :style="{
                fontSize: t('lang') === 'Pilipinas' ? '30px' : '26px',
              }"
            >
              {{ t("出行达人") }}
            </h2>
            <div
              class="role_hr2"
              :style="{
                marginTop: t('lang') === 'Pilipinas' ? '20px' : '12px',
              }"
            ></div>
            <p
              :style="{
                fontSize: t('lang') === 'Pilipinas' ? '18px' : '15px',
              }"
            >
              {{ t("每日可获得 5 个额外行程次数") }}
            </p>
          </div>
          <div class="role_child">
            <img src="../../assets/Component 370 (2).png" alt="" />
            <h2
              :style="{
                fontSize: t('lang') === 'Pilipinas' ? '30px' : '26px',
              }"
            >
              {{ t("车队老板") }}
            </h2>
            <div
              class="role_hr1"
              :style="{
                marginTop: t('lang') === 'Pilipinas' ? '20px' : '12px',
              }"
            ></div>
            <p
              :style="{
                fontSize: t('lang') === 'Pilipinas' ? '18px' : '15px',
              }"
            >
              {{ t("每日可获得 100 个额外行程次数，可以将行程次数赠送给好友") }}
            </p>
          </div>
          <div class="role_child2">
            <img src="../../assets/Component 370 (3).png" alt="" />
            <h2
              :style="{
                fontSize: t('lang') === 'Pilipinas' ? '30px' : '26px',
              }"
            >
              {{ t("城市合伙人") }}
            </h2>
            <div
              class="role_hr2"
              :style="{
                marginTop: t('lang') === 'Pilipinas' ? '20px' : '12px',
              }"
            ></div>
            <p
              :style="{
                fontSize: t('lang') === 'Pilipinas' ? '18px' : '15px',
              }"
            >
              {{
                t("每日可获得 1000 个额外行程次数，可以将行程次数赠送给好友")
              }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="role_page">
      <h1>{{ t("角色") }}</h1>

      <div class="role_bg_child">
        <div class="role_child">
          <img src="../../assets/Component 370.png" alt="" />
          <h2>{{ t("顺风车司机") }}</h2>
          <div class="role_hr1"></div>
          <p>{{ t("每日可获得额外 2 个行程次数") }}</p>
        </div>
        <div class="role_child2">
          <img src="../../assets/Component 370 (1).png" alt="" />
          <h2>{{ t("出行达人") }}</h2>
          <div class="role_hr2"></div>
          <p>{{ t("每日可获得 5 个额外行程次数") }}</p>
        </div>
        <div class="role_child">
          <img src="../../assets/Component 370 (2).png" alt="" />
          <h2>{{ t("车队老板") }}</h2>
          <div class="role_hr1"></div>
          <p>
            {{ t("每日可获得 100 个额外行程次数，可以将行程次数赠送给好友") }}
          </p>
        </div>
        <div class="role_child2">
          <img src="../../assets/Component 370 (3).png" alt="" />
          <h2>{{ t("城市合伙人") }}</h2>
          <div class="role_hr2"></div>
          <p>
            {{ t("每日可获得 1000 个额外行程次数，可以将行程次数赠送给好友") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
const { t } = useI18n();
</script>

<style lang="scss" scoped>
@media (max-width: 600px) {
  .role {
    display: none;
  }

  .role_page {
    display: block;
    width: 95%;
    margin: 80px auto;
    color: #fff;

    h1 {
      font-size: 24px;
      font-weight: 600;
    }

    .role_bg_child {
      display: flex;
      width: calc(100vw - 30px);
      overflow-x: scroll;
      padding: 20px 0;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE and Edge */

      &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, and Opera */
      }

      .role_child,
      .role_child2 {
        width: 165px;
        height: 257px;
        background: url("../../assets/Rectangle\ 511111409.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        flex-shrink: 0;

        img {
          width: 74px;
          height: 105px;
          margin-left: 37px;
        }

        h2 {
          font-size: 15px;
          margin-left: 27px;
          margin-top: 13px;
        }

        p {
          width: 115px;
          font-size: 9px;
          font-weight: 400;
          margin-left: 32px;
          margin-top: 12px;
        }
      }

      .role_hr1,
      .role_hr2 {
        width: 118px;
        height: 1px;
        margin-left: 27px;
        margin-top: 18px;
      }

      .role_hr1 {
        background: linear-gradient(90deg, #00ff80 0%, #f2ff5c 100%);
      }

      .role_hr2 {
        background: #7081e8;
      }

      .role_child2 {
        background: url("../../assets/Rectangle\ 511111409\ (1).png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}

@media (min-width: 601px) {
  .role_page {
    display: none;
  }

  .role {
    width: 100%;
    height: 100vh;
    background: #0e0e10;
    color: #fff;

    .role-bg {
      width: 100%;
      height: 100vh;
      background: url("../../assets/Group\ 1312318553.png") no-repeat;
      background-size: 100% 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h1 {
        font-size: 40px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 60px;
      }

      .role_bg_child {
        display: flex;

        .role_child,
        .role_child2 {
          width: 310px;
          height: 500px;
          background: url("../../assets/Rectangle\ 511111409.png") no-repeat;
          background-size: 100% 100%;
          display: flex;
          flex-direction: column;

          img {
            width: 149px;
            height: 210px;
            margin-left: 60px;
            margin-top: 40px;
          }

          h2 {
            font-size: 30px;
            margin-left: 70px;
            margin-top: 20px;
          }

          p {
            width: 190px;
            font-size: 18px;
            font-weight: 400;
            margin-left: 70px;
            margin-top: 20px;
          }
        }

        .role_hr1,
        .role_hr2 {
          width: 190px;
          height: 1px;
          margin-left: 70px;
          margin-top: 20px;
        }

        .role_hr1 {
          background: linear-gradient(90deg, #00ff80 0%, #f2ff5c 100%);
        }

        .role_hr2 {
          background: #7081e8;
        }

        .role_child2 {
          background: url("../../assets/Rectangle\ 511111409\ (1).png")
            no-repeat;
          background-size: 100% 100%;
        }
      }
    }
  }
}
</style>